<template>
  <div class="modal modal_wrap">
    <div class="modal_popup">
      <p class="text text_white">{{actions}} was successfully sent to the customer
        <span class="modal__close" @click="close">
         <svg-icon name="times"/>
      </span>
      </p>
      <div class="justify-content-between">
        <button class="btn btn_transparent" @click="close">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>

  import router from 'vue-router';
  import {mapGetters} from 'vuex';

  export default {
    name: "EstimateQuote",

    methods: {
      close() {
        this.$router.push({name: 'orderList'});
        this.$store.commit('orderPreview/estimateQuote', false);
        this.$store.commit('orderPreview/action', '');
      },
    },

    computed: {
      ...mapGetters({
        action: 'orderPreview/action',
      }),

      actions(){
        return _.upperFirst(this.action);
      }
    }

  }

</script>

<style scoped>

</style>
